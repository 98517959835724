import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { isInArray } from '../../utils/function';
import { useAuth } from '../../hooks/AuthProvider';

function CompanySidebar(props) {
  const location = useLocation();
  const auth = useAuth();

  const { pathname, search } = location;

  const isActive = (page) => search === `?page=${page}`;

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/company/${props._id}?page=offers`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('offers') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
            >
    

              <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('offers') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7z"/></svg>
              <span className={`text-sm font-medium ${isActive('offers') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Mes offres</span>
            </NavLink>
          </li>

{/*           {(isInArray(props.admins, auth.user.user._id) && props.typePage === "company") &&
            <
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  end
                  to={`/company/${props._id}?page=employees`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('employees') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
                >
                  <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${isActive('employees') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                    <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z" />
                  </svg>
                  <span className={`text-sm font-medium ${isActive('employees') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Mes salariés</span>
                </NavLink>
              </li>

              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  end
                  to={`/company/${props._id}?page=partnerships`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('partnerships') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
                >
                  <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${isActive('partnerships') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                    <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
                  </svg>
                  <span className={`text-sm font-medium ${isActive('partnerships') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Mes partenaires</span>
                </NavLink>
              </li>
            </>
          } */}

{/*           {(isInArray(props.admins, auth.user.user._id)) &&
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/company/${props._id}?page=annuary`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('annuary') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
              >
                <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${isActive('annuary') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                  <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
                </svg>
                <span className={`text-sm font-medium ${isActive('annuary') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Annuaire</span>
              </NavLink>
            </li>
          } */}

          {isInArray(props.admins, auth.user.user._id, 'admin') &&
            <>
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  end
                  to={`/company/${props._id}?page=settings`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('settings') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
                >

                  <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('settings') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`} width="40" height="40" viewBox="0 0 256 256"><g id="galaSettings0" fill="none" stroke="currentColor" stroke-dasharray="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="4" stroke-opacity="1" stroke-width="16"><path id="galaSettings1" d="M 48.000002,16 H 208 c 17.728,0 32,14.272 32,32 v 160 c 0,17.728 -14.272,32 -32,32 H 48.000002 c -17.728,0 -32,-14.272 -32,-32 V 48 c 0,-17.728 14.272,-32 32,-32 z"/><path id="galaSettings2" d="M 64.000006,64.000001 H 79.999993"/><path id="galaSettings3" d="m 79.999996,-96.000015 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16.000005 16,16 0 0 1 16,16.000005 z" transform="rotate(90)"/><path id="galaSettings4" d="m 112.00001,64.000353 79.99997,-3.52e-4"/><path id="galaSettings5" d="M 191.99998,128 H 176"/><path id="galaSettings6" d="m 144,159.99997 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16 16,16 0 0 1 16,16 z" transform="matrix(0 1 1 0 0 0)"/><path id="galaSettings7" d="M 143.99998,128.00035 64.000006,128"/><path id="galaSettings8" d="M 64.000006,192.00001 H 79.999993"/><path id="galaSettings9" d="m 208,-96.000015 a 16,16 0 0 1 -16,16 16,16 0 0 1 -16,-16 16,16 0 0 1 16,-16.000005 16,16 0 0 1 16,16.000005 z" transform="rotate(90)"/><path id="galaSettingsa" d="m 112.00001,192.00036 79.99997,-3.5e-4"/></g></svg>
                  <span className={`text-sm font-medium ${isActive('settings') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{props.typeCompany === 'multi' ? "Gestion compte" : "Gestion page"}</span>
                </NavLink>
              </li>

{  props.typePage === "company" &&           <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  end
                  to={`/company/${props._id}?page=settings-sub`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('settings-sub') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
                >
               
                  <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('settings-sub') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="200" height="200" viewBox="0 0 56 56"><path fill="currentColor" d="M46.847 8.448A6 6 0 0 1 47.052 10l-.001 6.001l.174.004A6 6 0 0 1 53 22v24a6 6 0 0 1-6 6H9a6 6 0 0 1-6-6V22l.003-.205l.01-.18a6.002 6.002 0 0 1 4.436-6.167l32.05-11.243a6 6 0 0 1 7.348 4.243M47 20H9a2 2 0 0 0-1.995 1.851l-.005.15v24a2 2 0 0 0 1.85 1.994L9 48h38a2 2 0 0 0 1.995-1.85L49 46V22a2 2 0 0 0-1.85-1.994zM37 30a4 4 0 1 1 0 8a4 4 0 0 1 0-8m3.68-21.965l-.048.011L17.957 16h25.094v-6a2 2 0 0 0-.03-.347l-.038-.17a2 2 0 0 0-2.304-1.448"/></svg>
                  <span className={`text-sm font-medium ${isActive('settings-sub') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Gestion abonnement</span>

                </NavLink>

                <NavLink
                  end
                  to={`/company/${props._id}?page=dashboard-sub`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${isActive('dashboard-sub') && 'bg-royalblue-50 dark:bg-royalblue-500/30'}`}
                >
               
                  <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 shrink-0 fill-current mr-2 ${isActive('dashboard-sub') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-400 dark:text-slate-500'}`}  width="200" height="200" viewBox="0 0 56 56"><path fill="currentColor" d="M46.847 8.448A6 6 0 0 1 47.052 10l-.001 6.001l.174.004A6 6 0 0 1 53 22v24a6 6 0 0 1-6 6H9a6 6 0 0 1-6-6V22l.003-.205l.01-.18a6.002 6.002 0 0 1 4.436-6.167l32.05-11.243a6 6 0 0 1 7.348 4.243M47 20H9a2 2 0 0 0-1.995 1.851l-.005.15v24a2 2 0 0 0 1.85 1.994L9 48h38a2 2 0 0 0 1.995-1.85L49 46V22a2 2 0 0 0-1.85-1.994zM37 30a4 4 0 1 1 0 8a4 4 0 0 1 0-8m3.68-21.965l-.048.011L17.957 16h25.094v-6a2 2 0 0 0-.03-.347l-.038-.17a2 2 0 0 0-2.304-1.448"/></svg>
                  <span className={`text-sm font-medium ${isActive('dashboard-sub') ? 'text-royalblue-500 dark:text-royalblue-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Dashboard</span>

                </NavLink>
              </li>}
            </>
          }

          
        </ul>
      </div>
    </div>
  );
}

export default CompanySidebar;
