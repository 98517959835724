import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import useAuthRequest from '../../../services/requests.service.js';
import { DotLoader } from 'react-spinners';
import { ConfigProvider, DatePicker, Table } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';


const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Offre',
    dataIndex: 'deal',
    key: 'deal.offer',
    render: (text) => <span>{text.offer}</span>,
  },
  {
    title: 'Nb de vue',
    dataIndex: 'views',
    key: 'views',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Nb de clic "J\'en profite":',
    dataIndex: 'clicks',
    key: 'clicks',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Nb de sauvegarde',
    dataIndex: 'saves',
    key: 'saves',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Taux de conversion',
    dataIndex: 'conversionRate',
    key: 'conversionRate',
    render: (text) => <span>{text == 0?'-':text+'%'}</span>,
  },
  {
    title: 'Taux d\'abandon',
    dataIndex: 'failedRate',
    key: 'failedRate',
    render: (text) => <span>{text == 100?'-':text+'%'}</span>,
  },


];

const CustomHeader = (props) => {
  return (
    <th {...props} className="bg-blue-500 text-white p-2 text-left">
      {props.children}
    </th>
  );
};

const DashboardSub = ({ page, setPage }) => {


  const { id } = useParams();
  const request = useAuthRequest();
  const [datas, setDatas] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dates, setDates] = useState([null, null]); // [startDate, endDate]
  const [datasDeals, setDatasDeals] = useState(null)
  const [datesDeals, setDatesDeals] = useState([null, null]); // [startDate, endDate]


  useEffect(() => {
    if (page) {

      getDatas()

    }
  }, [])


  const onChange = async (values) => {
    // Extract startDate and endDate from the picker
    const [startDate, endDate] = values || [null, null];

    // Convert to 'YYYY-MM-DD' format or set to null if not selected
    const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';

    // Update the state
    setDates([startDate, endDate]);
    setIsLoading(true);

    // Fetch data with the formatted date range
    try {
        const res = await request.getDataAPI(
            `/interactions/${page._id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
        setDatas(res);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        setIsLoading(false);
    }
};


const onChangeDeals = async (values) => {
  // Extract startDate and endDate from the picker
  const [startDate, endDate] = values || [null, null];

  // Convert to 'YYYY-MM-DD' format or set to null if not selected
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';

  // Update the state
  setDatesDeals([startDate, endDate]);
  setIsLoading(true);

  // Fetch data with the formatted date range
  try {
      const res = await request.getDataAPI(
          `/interactionsDeals/${page._id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      );
      setDatas(res);
  } catch (error) {
      console.error('Error fetching data:', error);
  } finally {
      setIsLoading(false);
  }
};


  const getDatas = async () => {
    setIsLoading(true)

    let res = await request.getDataAPI('/interactions/' + page._id);
    setDatas(res)
     res = await request.getDataAPI('/interactionsDeals/' + page._id);
     setDatasDeals(res)

    setIsLoading(false)

  }


  return (
    <div className='h-full m-4 md:w-full flex flex-col'>
      {isLoading && <div className="  min-h-[200px]  flex justify-center items-center">  <DotLoader size={60} color="#6076E7" /></div>}
      <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-4">Dashboard</h2>
      {datas &&
        <div className='flex flex-col'>
          <div className='flex justify-between py-6'> 
            <span className='text-lg font-semibold text-slate-800'>Vue générale</span>                 
            <ConfigProvider locale={locale}>
              <div>
                <RangePicker
                  allowEmpty={[true, true]} // Allow both dates to be empty
                  value={dates.map((date) => (date ? dayjs(date) : null))} // Convert back to dayjs for display
                  onChange={onChange}
                  format="DD/MM/YYYY" // French date format
                  placeholder={['Date de début', 'Date de fin']} // Custom placeholders in French
                />

              </div>
            </ConfigProvider>
          </div>
          <div className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-4  gap-6 '>
            <div className='flex flex-col justify-between bg-slate-100   rounded-2xl aspect-square p-4'>
              <span className='font-semibold'>Nombre d'utilisateurs</span>
              <div className='flex flex-col items-center justify-center'>
                <span className='font-semibold text-6xl '>{datas.totalUsers}</span>
                <span className='font-light text-sm italic text-center '>{datas.totalUsers > 1 ? 'utilisateurs' : 'utilisateur'} utilisant votre abonnement</span>

              </div>
              <span></span>
            </div>



            <div className='flex flex-col justify-between bg-slate-100   rounded-2xl aspect-square p-4'>
              <span className='font-semibold'>Nombre d'annonces visionnées</span>
              <div className='flex flex-col items-center justify-center'>
                <span className='font-semibold text-6xl '>  {Number(datas.engagement.totalViews).toFixed(0)}
                </span>
              </div>
              <span></span>
            </div>

            <div className='flex flex-col justify-between bg-slate-100   rounded-2xl aspect-square p-4'>
              <span className='font-semibold'>Moyenne d'annonces visionnées</span>
              <div className='flex flex-col items-center justify-center'>
                <span className='font-semibold text-6xl '>{Number(datas.engagement.avgViewsPerActiveUser).toFixed(1)}</span>
                <span className='font-light text-sm italic text-center '> par utilisateur</span>
              </div>
              <span></span>
            </div>

            <div className='flex flex-col justify-between bg-slate-100   rounded-2xl aspect-square p-4'>
              <span className='font-semibold'>Nombre d'annonces cliquées</span>
              <div className='flex flex-col items-center justify-center'>
                <span className='font-semibold text-6xl '>{datas.engagement.totalClicks}</span>
                <span className='font-light text-sm italic text-center '>{datas.engagement.totalClicks > 1 ? 'utilisateurs ont cliqués sur ' : 'utilisateur a cliqué sur'} "J'en profite"</span>
              </div>
              <span></span>
            </div>

            <div className='flex flex-col justify-between bg-slate-100   rounded-2xl aspect-square p-4'>
              <span className='font-semibold'>Moyenne d'annonces cliquées</span>
              <div className='flex flex-col items-center justify-center'>
                <span className='font-semibold text-6xl '>{Number(datas.engagement.avgClicksPerActiveUser).toFixed(1)}</span>
                <span className='font-light text-sm italic text-center '> par utilisateur</span>
              </div>
              <span></span>
            </div>


          </div>

        </div>
      }

{datasDeals &&
        <div className='flex flex-col mt-4'>
          <div className='flex justify-between py-6'> 
            <span className='text-lg font-semibold text-slate-800'>Statistiques promotions </span>                 
            <ConfigProvider locale={locale}>
              <div>
                <RangePicker
                  allowEmpty={[true, true]} // Allow both dates to be empty
                  value={dates.map((date) => (date ? dayjs(date) : null))} // Convert back to dayjs for display
                  onChange={onChangeDeals}
                  format="DD/MM/YYYY" // French date format
                  placeholder={['Date de début', 'Date de fin']} // Custom placeholders in French
                />

              </div>
            </ConfigProvider>
          </div>

          

          <Table
        columns={columns}

        dataSource={datasDeals.deals}
        className="bg-white dark:bg-slate-800 shadow rounded-lg  overflow-x-auto"
        components={{
          header: {
            cell: CustomHeader,
          },
        }}
        locale={{
          emptyText: 'Aucune promotion disponible', // Custom message for no data
        }}
      />


        </div>
      }
    </div>
  )
}

export default DashboardSub